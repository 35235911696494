import logo from './logo.svg';
import './App.css';

import React, { useEffect, useState } from 'react';
import { AuthProvider } from './AuthContext';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import NotFound from './components/pages/NotFound';
import Account from './components/pages/UserDetail/Account'
import DepositStepOne from './components/pages/UserDetail/DepositStepOne';
import DepositOnlineTransfer from './components/pages/UserDetail/DepositOnlineTransfer';
import DepositPaypay89 from './components/pages/UserDetail/DepositPaypay89';
import ManageBankAccount from './components/pages/UserDetail/ManageBankAccount';
import EditBankAccount from './components/pages/UserDetail/EditBankAccount';
import AddBankAccount from './components/pages/UserDetail/AddBankAccount';
import Withdraw from './components/pages/UserDetail/Withdraw';
import InviteFriend from './components/pages/UserDetail/InviteFriend';
import TransactionHistory from './components/pages/UserDetail/TransactionHistory';
import PromotionHistory from './components/pages/UserDetail/PromotionHistory';
import DepositSuccess from './components/pages/UserDetail/DepositSuccess';
import DepositFailed from './components/pages/UserDetail/DepositFailed';
import WithdrawLocalTransfer from './components/pages/UserDetail/WithdrawLocalTransfer';
import { ThemeProvider } from './ThemeContext';

import HomePage from './components/pages/HomePage';
import InviteFriendPage from './components/pages/InviteFriendPage';
import CommissionPage from './components/pages/CommissionPage';
import RankingPage from './components/pages/RankingPage';
import LuckyWheelPage from './components/pages/LuckyWheelPage';
import PromotionPage from './components/pages/PromotionPage';

import { AnimatePresence, motion } from 'framer-motion';
import { GameDataProvider } from './GameDataContext';



const fadeScaleVariants = {
  initial: {
    opacity: 0,
    scale: 0.95,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.95,
  },
};

const fadeScaleTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.2,
};

const PageWrapper = ({ children }) => {
  return children;
  // return (
    // <motion.div
    //   style={{ backgroundColor: 'black' }}
    //   initial="initial"
    //   animate="in"
    //   exit="out"
    //   variants={fadeScaleVariants}
    //   transition={fadeScaleTransition}
    // >
    //   {children}
    // </motion.div>
  // );
};

const AppRoutes = ({ imageSettingsLoaded }) => {
  const location = useLocation();
  
  return (
    <ThemeProvider>
      <GameDataProvider>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage imageSettingsLoaded={imageSettingsLoaded}/>}/>
            <Route path="/invitation" element={<InviteFriendPage />}/>
            <Route path="/commission" element={<CommissionPage />}/>
            <Route path="/ranking" element={<RankingPage />}/>
            <Route path="/luckywheel" element={<LuckyWheelPage />}/>
            <Route path="/promotion" element={<PromotionPage />}/>
            <Route path="/user/detail/account" element={<PageWrapper><Account /></PageWrapper>} />
            <Route path="/user/detail/managebank" element={<PageWrapper><ManageBankAccount /></PageWrapper>} />
            <Route path="/user/detail/managebank/add" element={<PageWrapper><AddBankAccount /></PageWrapper>} />
            <Route path="/user/detail/managebank/edit/:id" element={<PageWrapper><EditBankAccount /></PageWrapper>} />
            <Route path="/user/detail/deposit" element={<PageWrapper><DepositStepOne /></PageWrapper>} />
            <Route path="/user/detail/deposit/success" element={<PageWrapper><DepositSuccess /></PageWrapper>} />
            <Route path="/user/detail/deposit/failed" element={<PageWrapper><DepositFailed /></PageWrapper>} />
            <Route path="/user/detail/deposit/onlinetransfer" element={<PageWrapper><DepositOnlineTransfer /></PageWrapper>} />
            <Route path="/user/detail/deposit/paypay89" element={<PageWrapper><DepositPaypay89 /></PageWrapper>} />
            <Route path="/user/detail/withdraw" element={<PageWrapper><Withdraw /></PageWrapper>} />
            <Route path="/user/detail/withdraw/localbanktransfer" element={<PageWrapper><WithdrawLocalTransfer /></PageWrapper>} />
            <Route path="/user/detail/transaction/history" element={<PageWrapper><TransactionHistory /></PageWrapper>} />
            <Route path="/user/detail/promotion/history" element={<PageWrapper><PromotionHistory /></PageWrapper>} />
            <Route path="/user/detail/invitefriend" element={<PageWrapper><InviteFriend /></PageWrapper>} />
            <Route path="*" element={<NotFound />}/>
          </Routes>
        </AnimatePresence>
      </GameDataProvider>
    </ThemeProvider>
  );
};

function App() {

  const apiUrl = process.env.REACT_APP_API_URL;
  const [imageSettingsLoaded, setImageSettingsLoaded] = useState(false);

  useEffect(() => {
    const fetchImageSettings = async () => {
      try {
        const response = await fetch(apiUrl + '/api/imagesettings');
        const data = await response.json();
        localStorage.setItem('imageSettings', JSON.stringify(data));
        localStorage.setItem('lastImageSettingsFetch', Date.now());
        setImageSettingsLoaded(true); // Indicate that the settings have been fetched
      } catch (error) {
        console.error('Error fetching image settings:', error);
      }
    };

    const lastFetchTime = localStorage.getItem('lastImageSettingsFetch');
    const oneDayInMilliseconds = 60000; // 24 hours = 86400000

    if (!lastFetchTime || (Date.now() - lastFetchTime > oneDayInMilliseconds)) {
      fetchImageSettings();
    } else {
      setImageSettingsLoaded(true); // Settings are already available in localStorage
    }

    const interval = setInterval(() => {
      fetchImageSettings();
    }, oneDayInMilliseconds);

    return () => clearInterval(interval);
  }, [apiUrl]);

  return (
    <AuthProvider>
      <Router>
        <AppRoutes imageSettingsLoaded={imageSettingsLoaded}/>
      </Router>
    </AuthProvider>
  );
}

export default App;
