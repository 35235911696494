import '../../App.css';

import Header from '../layout/Header';
import MainSideBar from '../layout/MainSideBar';
import AnnouncementBar from '../layout/AnnouncementBar';
import FooterWithProvider from '../layout/FooterWithProvider';
import Footer from '../layout/Footer';
import React, { useState, useContext, useEffect } from 'react';

import LoginModal from '../common/LoginModal';
import { useAuth } from '../../AuthContext';

import RegisterModal from '../common/RegisterModal';
import AccountModal from '../common/AccountModal';
import MenuModal from '../common/MenuModal';
import RankModal from '../modal/RankModal';

import { ThemeContext } from '../../ThemeContext';
import axios from 'axios';

function CommissionPage(){
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isMenuModalVisible, seIsMenuModalVisible] = useState(false);
  const { auth } = useAuth();
  const [info, setInfo] = useState(null);

  const showLoginModal = () => setIsLoginModalVisible(true);
  const cancelLoginModal = () => setIsLoginModalVisible(false);

  const showRegisterModal = () => setIsRegisterModalVisible(true);
  const cancelRegisterModal = () => setIsRegisterModalVisible(false);

  const showAccountModal = () => setIsAccountModalVisible(true);
  const cancelAccountModal = () => setIsAccountModalVisible(false);

  const showMenuModal = () => seIsMenuModalVisible(true);
  const cancelMenuModal = () => seIsMenuModalVisible(false);

  const handleAccountClick = () => {
    showAccountModal(true);
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const { theme } = useContext(ThemeContext);
  const [backgroundUrl, setBackgroundUrl] = useState('');

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('imageSettings'));
    if (settings) {
      // Find the correct setting based on type, theme, and device (fixed to 'desktop')
      const layoutSetting = settings.find(setting => 
        setting.type === 'main_background' && 
        setting.theme === theme &&
        setting.device === 'desktop' // Fixed device to 'desktop'
      );
      
      if (layoutSetting && layoutSetting.uploadImage) {
        // Construct the correct URL for the background image
        const imageUrl = `${apiUrl}${layoutSetting.uploadImage.url.replace(/\\/g, '/')}`;
        setBackgroundUrl(imageUrl);
      }
    }
  }, [theme, apiUrl]);

  const layoutStyle = {
    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh'
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/member/commission/info`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      setInfo(response.data);
    } catch (error) {
      console.error('Error fetching commission Info:', error);
    }
  };
  
  return (
    <>
      <Header onLoginClick={showLoginModal} onRegisterClick={showRegisterModal} 
              onUserDetail={handleAccountClick}
              onMenuClick={showMenuModal}
      />

      <MainSideBar/>

      <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} onRegisterClick={showRegisterModal}/>
      <RegisterModal visible={isRegisterModalVisible} onClose={cancelRegisterModal} onLogin={showLoginModal}/>
      <AccountModal visible={isAccountModalVisible} onClose={cancelAccountModal}/>

      <div className="layout" style={layoutStyle}>
            <main className="main-content">

                <AnnouncementBar/>

                {info && (
                  <div className="info-section">
                    <h2>Commission Info</h2>
                    <p>{info.titleTh}</p>
                    <p>{info.descriptionTh}</p>
                    <p>{info.titleEn}</p>
                    <p>{info.descriptionEn}</p>
                  </div>
                )}

            </main>
            
            <RankModal/>
            <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal}/>
            
            <FooterWithProvider/>
            <Footer />
        </div>
      
    </>
  );
}

export default CommissionPage;