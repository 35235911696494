import React, { useState, useEffect } from 'react';
import './PromotionSection.css';
import PromotionModal from '../common/PromotionModal';
import { useAuth } from '../../AuthContext';

function PromotionSection() {

    const [isPromotionModalVisible, setIsPromotionModalVisible] = useState(false);
    const showPromotionModal = () => setIsPromotionModalVisible(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { auth } = useAuth();

    const cancelPromotionModal = () => {
        setIsPromotionModalVisible(false);
        setSelectedPromotion(null);
    };

    // const [ promotions, setPromotions ] = useState([
    //     { id: 1, name: 'ฝากครั้งแรก 100 รับ 150', description: 'ฝากครั้งแรก 100 รับ 150ฝากครั้งแรก 100 รับ 150ฝากครั้งแรก 100 รับ 150ฝากครั้งแรก 100 รับ 150ฝากครั้งแรก 100 รับ 150', url: 'https://sacasino.gg/media/cache/strip/202304/promotion/9d9f735e2f5d0a3b902521187cb2a644.webp' },
    //     { id: 2, name: 'ฝากครั้งแรก 300 รับ 500', description: 'ฝากครั้งแรก 300 รับ 500ฝากครั้งแรก 300 รับ 500ฝากครั้งแรก 300 รับ 500ฝากครั้งแรก 300 รับ 500ฝากครั้งแรก 300 รับ 500', url: 'https://sacasino.gg/media/cache/strip/202304/promotion/1d6aecd2607a881ec3c4d99ea15c853f.webp' },
    //     { id: 3, name: 'ฝากครั้งแรก รับเพิ่ม 50%', description: 'ฝากครั้งแรก รับเพิ่ม 50%ฝากครั้งแรก รับเพิ่ม 50%ฝากครั้งแรก รับเพิ่ม 50%ฝากครั้งแรก รับเพิ่ม 50%ฝากครั้งแรก รับเพิ่ม 50%ฝากครั้งแรก รับเพิ่ม 50%', url: 'https://sacasino.gg/media/cache/strip/202304/promotion/b6c302ad63064bcfc515a665962ad1c9.webp' },
    //     { id: 4, name: 'ฝาก 1000 รับเพิ่ม 100', description: 'ฝาก 1000 รับเพิ่ม 100ฝาก 1000 รับเพิ่ม 100ฝาก 1000 รับเพิ่ม 100ฝาก 1000 รับเพิ่ม 100ฝาก 1000 รับเพิ่ม 100ฝาก 1000 รับเพิ่ม 100', url: 'https://sacasino.gg/media/cache/strip/202304/promotion/f5ba9b4a1e5a461fed832a0f5df2ce79.jpg' },
    //     { id: 5, name: '2% ทุกยอดฝาก', description: '2% ทุกยอดฝาก2% ทุกยอดฝาก2% ทุกยอดฝาก2% ทุกยอดฝาก2% ทุกยอดฝาก2% ทุกยอดฝาก2% ทุกยอดฝาก2% ทุกยอดฝาก2% ทุกยอดฝาก2% ทุกยอดฝาก', url: 'https://sacasino.gg/media/cache/strip/202304/promotion/dc0013f511e61b52d0d05776495d96d6.jpg' },
    // ]);

    const [ promotions, setPromotions ] = useState([]);

    const [ selectedPromotion, setSelectedPromotion ] = useState(null);

    const handlePromotionClick = (promotionId) => {
        const promotion = promotions.find(promo => promo.id === promotionId);
        setSelectedPromotion(promotion); // Set the clicked promotion
        showPromotionModal();
    };

    const fetchPromotions = async (username) => {

        const response = await fetch(apiUrl+'/member/promotion/all', {
            method: 'GET',
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setPromotions(data);
        console.log(data);
    };

    useEffect(() => {
        fetchPromotions();
    }, [auth.token]);

    return (
        <div className="promotion-container">
            {promotions.map((promotion) => (
                <div className="promotion-button" onClick={() => handlePromotionClick(promotion.id)}>
                    <img
                        className="promotion-image"
                        src={promotion.imgUrlHorizontal}
                        alt={promotion.name}
                    />
                </div>
            ))}

            <PromotionModal visible={isPromotionModalVisible} promotion={selectedPromotion} onClose={cancelPromotionModal}/>
        </div>
    );
}

export default PromotionSection;