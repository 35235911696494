import React, { useState, useMemo } from 'react';
import { Form, message  } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import DataTable from '../../table/DataTable';
import './UserDetail.css';
import { useTranslation } from 'react-i18next';

function CurrentPromotion(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [accountData, setAccountData] = useState(null);
    const [data, setData] = useState([]);
    const { t } = useTranslation();
    
    const columns = useMemo(() => [
        { Header: 'Promotion', accessor: 'bankName' },
        { Header: 'Promo Code', accessor: 'accountNumber' },
        { Header: 'วันที่ขอ', accessor: 'fullName' }
    ], []);

    return (
        <div className="account-content-container">
            <h1 className="account-modal-title">{t('current_promotion')}</h1>
            <div className="account-content">
                <DataTable columns={columns} data={data} />
                <br></br>
            </div>
        </div>
    );
}

export default CurrentPromotion;