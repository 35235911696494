import React, { createContext, useContext, useReducer, useEffect, useCallback } from 'react';

const initialState = {
  gameType: "SLOT",
  providers: [],
  loadingProvider: false,
  selectedProvider: null,
  providerPage: 0,
  providerHasMore: true,

  categories: [],
  loadingCategory: false,
  selectedCategory: "SLOT",

  types: [],
  loadingType: false,

  games: [],
  loadingGame: false,
  gamePage: 0,
  hasMore: true,
};

const GameDataContext = createContext();

const gameDataReducer = (state, action) => {
  switch (action.type) {
    case 'SET_GAME_TYPE':
      return { ...state, gameType: action.payload };
    case 'SET_PROVIDERS':
      return { ...state, providers: action.payload };
    case 'SET_LOADING_PROVIDER':
      return { ...state, loadingProvider: action.payload };
    case 'SET_SELECTED_PROVIDER':
      return { 
        ...state, 
        selectedProvider: action.payload, 
        games: [],          // Reset games to empty
        gamePage: 0,        // Reset the gamePage to 0
        hasMore: true       // Ensure we can fetch more games
      };
    case 'SET_PROVIDER_PAGE':
      return { ...state, providerPage: action.payload };
    case 'SET_PROVIDER_HAS_MORE':
      return { ...state, providerHasMore: action.payload };
    case 'SET_CATEGORIES':
      return { ...state, categories: action.payload };
    case 'SET_LOADING_CATEGORY':
      return { ...state, loadingCategory: action.payload };
    case 'SET_SELECTED_CATEGORY':
      return { ...state, selectedCategory: action.payload };
    case 'SET_TYPES':
      return { ...state, types: action.payload };
    case 'SET_LOADING_TYPE':
      return { ...state, loadingType: action.payload };
    case 'SET_GAMES':
      return { ...state, games: action.payload };
    case 'SET_LOADING_GAME':
      return { ...state, loadingGame: action.payload };
    case 'SET_GAME_PAGE':
      return { ...state, gamePage: action.payload };
    case 'SET_HAS_MORE':
      return { ...state, hasMore: action.payload };
    default:
      return state;
  }
};

// Fetch providers
const fetchProviders = async (dispatch, gameType, loadingProvider, apiUrl) => {
  if (loadingProvider) return;
  dispatch({ type: 'SET_LOADING_PROVIDER', payload: true });

  try {
    const response = await fetch(`${apiUrl}/game/get-provider-lists?gameType=${gameType}&limit=100`);
    const data = await response.json();
    if (response.ok && Array.isArray(data)) {
      dispatch({ type: 'SET_PROVIDERS', payload: data });
    }
  } catch (error) {
    console.error('Error fetching providers:', error);
  } finally {
    dispatch({ type: 'SET_LOADING_PROVIDER', payload: false });
  }
};

// Fetch categories
const fetchCategories = async (dispatch, loadingCategory, apiUrl) => {
  if (loadingCategory) return;
  dispatch({ type: 'SET_LOADING_CATEGORY', payload: true });

  try {
    const response = await fetch(`${apiUrl}/game/get-category-lists`);
    const data = await response.json();
    if (response.ok && Array.isArray(data)) {
      dispatch({ type: 'SET_CATEGORIES', payload: data });
    }
  } catch (error) {
    console.error('Error fetching categories:', error);
  } finally {
    dispatch({ type: 'SET_LOADING_CATEGORY', payload: false });
  }
};

// Fetch types
const fetchTypes = async (dispatch, loadingType, apiUrl) => {
  if (loadingType) return;
  dispatch({ type: 'SET_LOADING_TYPE', payload: true });

  try {
    const response = await fetch(`${apiUrl}/game/get-type-lists`);
    const data = await response.json();
    if (response.ok && Array.isArray(data)) {
      dispatch({ type: 'SET_TYPES', payload: data });
    }
  } catch (error) {
    console.error('Error fetching types:', error);
  } finally {
    dispatch({ type: 'SET_LOADING_TYPE', payload: false });
  }
};

// Fetch games
const fetchGames = async (dispatch, apiUrl, gamePage, games, loadingGame, selectedProvider) => {
  if (loadingGame) return;
  dispatch({ type: 'SET_LOADING_GAME', payload: true });

  try {
    const url = `${apiUrl}/game/get-game-lists?page=${gamePage}&limit=10&providerName=${selectedProvider}`;
    console.log(url);
    const response = await fetch(url);
    
    const data = await response.json();
    if (response.ok && Array.isArray(data)) {
      dispatch({ type: 'SET_GAMES', payload: [...games, ...data] });
      dispatch({ type: 'SET_HAS_MORE', payload: data.length === 10 });
      dispatch({ type: 'SET_GAME_PAGE', payload: gamePage + 1 });
    }
  } catch (error) {
    console.error('Error fetching games:', error);
  } finally {
    dispatch({ type: 'SET_LOADING_GAME', payload: false });
  }
};

export const loadMoreGames = (dispatch, apiUrl, state) => {
  fetchGames(dispatch, apiUrl, state.gamePage, state.games, state.loadingGame, state.selectedProvider);
};

// Action creators to manage state
export const setSelectedCategory = (dispatch, category) => {
  dispatch({ type: 'SET_SELECTED_CATEGORY', payload: category });
};

export const setSelectedProvider = (dispatch, provider) => {
  dispatch({ type: 'SET_SELECTED_PROVIDER', payload: provider });
};

export const GameDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(gameDataReducer, initialState);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch providers, categories, and types when gameType changes
  useEffect(() => {
    if (state.selectedCategory) {
      fetchProviders(dispatch, state.selectedCategory, state.loadingProvider, apiUrl);
      fetchCategories(dispatch, state.loadingCategory, apiUrl);
      fetchTypes(dispatch, state.loadingType, apiUrl);
    }
  }, [state.selectedCategory, apiUrl]);

  // Fetch games when selectedProvider changes
  useEffect(() => {
    if (state.selectedProvider) {
      console.log("provider changed : "+ state.selectedProvider);
      fetchGames(dispatch, apiUrl, state.gamePage, state.games, state.loadingGame, state.selectedProvider);
    }
  }, [state.selectedProvider, apiUrl]);

  return (
    <GameDataContext.Provider value={{ state, dispatch, loadMoreGames }}>
      {children}
    </GameDataContext.Provider>
  );
};

export const useGameDataContext = () => useContext(GameDataContext);
