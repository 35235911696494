import React, { useState, useEffect} from 'react';
import './SlideBar.css';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

function SlideBar({ onLogin }) {

    const [promotions, setPromotions] = useState([]);
    const navigate = useNavigate();
    const { auth } = useAuth();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/member/promotion/all`)
            .then(response => response.json())
            .then(data => {
                setPromotions(data);
            })
            .catch(error => console.error('Error fetching promotions:', error));
    }, []);

    const handlePromotionClick = () => {
        if (auth.isAuthenticated) {
            navigate('/promotion');
        } else {
            onLogin();
        }
    };

    return (
        <div className="carousel-container">

            <div className="carousel-left-shadow"></div>

            <div className="carousel-content">
                <Carousel 
                    autoPlay
                    infiniteLoop
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                    interval={3000}
                    stopOnHover
                    dynamicHeight
                >
                    <div>
                        <img src="/slide1.jpg" alt="Slide 1" style={{ background: 'none' }} />
                    </div>

                    {promotions.map((promotion) => (
                        <div key={promotion.id} onClick={handlePromotionClick} style={{cursor: 'pointer'}}>
                            <img src={promotion.imgUrlHorizontal} alt={promotion.name} style={{ background: 'none' }} />
                        </div>
                    ))}
                </Carousel>
            </div>

            <div className="carousel-right-shadow"></div>
            
        </div>
        
    );
}

export default SlideBar;