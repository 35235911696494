import '../../App.css';

import Header from '../layout/Header';
import MainSideBar from '../layout/MainSideBar';
import AnnouncementBar from '../layout/AnnouncementBar';
import FooterWithProvider from '../layout/FooterWithProvider';
import Footer from '../layout/Footer';
import React, { useState, useContext, useEffect } from 'react';
import { Button, Input, Form } from 'antd';

import LoginModal from '../common/LoginModal';
import { useAuth } from '../../AuthContext';

import RegisterModal from '../common/RegisterModal';
import AccountModal from '../common/AccountModal';
import MenuModal from '../common/MenuModal';
import RankModal from '../modal/RankModal';

import axios from 'axios';

import { ThemeContext } from '../../ThemeContext';

function InviteFriendPage(){
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isMenuModalVisible, seIsMenuModalVisible] = useState(false);
  const { auth } = useAuth();
  const [info, setInfo] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const showLoginModal = () => setIsLoginModalVisible(true);
  const cancelLoginModal = () => setIsLoginModalVisible(false);

  const showRegisterModal = () => setIsRegisterModalVisible(true);
  const cancelRegisterModal = () => setIsRegisterModalVisible(false);

  const showAccountModal = () => setIsAccountModalVisible(true);
  const cancelAccountModal = () => setIsAccountModalVisible(false);

  const showMenuModal = () => seIsMenuModalVisible(true);
  const cancelMenuModal = () => seIsMenuModalVisible(false);

  const handleAccountClick = () => {
    showAccountModal(true);
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  const { theme } = useContext(ThemeContext);
  const [backgroundUrl, setBackgroundUrl] = useState('');

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('imageSettings'));
    if (settings) {
      // Find the correct setting based on type, theme, and device (fixed to 'desktop')
      const layoutSetting = settings.find(setting => 
        setting.type === 'main_background' && 
        setting.theme === theme &&
        setting.device === 'desktop' // Fixed device to 'desktop'
      );
      
      if (layoutSetting && layoutSetting.uploadImage) {
        // Construct the correct URL for the background image
        const imageUrl = `${apiUrl}${layoutSetting.uploadImage.url.replace(/\\/g, '/')}`;
        setBackgroundUrl(imageUrl);
      }
    }
  }, [theme]);

  useEffect(() => {
    fetchInfo();
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated && auth.userData) {
        
        let affiliateUrl = process.env.REACT_APP_URL+"?code="+auth.userData.ownedAffiliate.code;
        setInputValue(affiliateUrl);
    }
  }, [auth]);

  const layoutStyle = {
    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh'
  };

  const fetchInfo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/member/invitefriend/info`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      setInfo(response.data);
    } catch (error) {
      console.error('Error fetching invitefriend Info:', error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue).then(() => {
        alert('Copied to clipboard!');
    }).catch(err => {
        alert('Failed to copy!');
    });
  };
  
  return (
    <>
      <Header onLoginClick={showLoginModal} onRegisterClick={showRegisterModal} 
              onUserDetail={handleAccountClick}
              onMenuClick={showMenuModal}
      />

      <MainSideBar/>

      <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} onRegisterClick={showRegisterModal}/>
      <RegisterModal visible={isRegisterModalVisible} onClose={cancelRegisterModal} onLogin={showLoginModal}/>
      <AccountModal visible={isAccountModalVisible} onClose={cancelAccountModal}/>

      <div className="layout" style={layoutStyle}>
        <main className="main-content">

            <AnnouncementBar/>

            <h1 className="page-title" style={{textAlign:"center", width:"100%"}}>ลิงก์รับทรัพย์ รับคอมมิชชั่นสูงถึง 3%</h1>
            <h1 className="page-title" style={{textAlign:"center", width:"100%"}}>รับเงินได้ทุกวัน Passive Income ที่แท้จริง</h1>

            <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
              
              <Input value={inputValue} style={{maxWidth: '300px', marginRight: '10px'}}/>
              <Button type="primary" onClick={handleCopy}>Copy</Button>
            </div>

            {info && (
              <div className="info-section">
                <h2>Lucky Wheel Info</h2>
                <p>{info.titleTh}</p>
                <p>{info.descriptionTh}</p>
                <p>{info.titleEn}</p>
                <p>{info.descriptionEn}</p>
              </div>
            )}
            
        </main>
        
        <RankModal/>
        <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal}/>
        
        <FooterWithProvider/>
        <Footer />
      </div>
      
    </>
  );
}

export default InviteFriendPage;