import React from 'react';
import './Header.css';
import { ReloadOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

function Header({ onLoginClick, onUserDetail, onMenuClick}) {

    const { auth } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const getRankImage = (rank) => {
        switch(rank) {
          case 'bronze':
            return '/rank_bronze.png';
          case 'silver':
            return '/rank_silver.png';
          case 'gold':
            return '/rank_gold.png';
          case 'platinum':
            return '/rank_platinum.png';
        }
    };

    const handleLinkClick = (path) => {
        if (auth.isAuthenticated) {
            navigate(path);
        } else {
            onLoginClick(); // Trigger the login callback if not authenticated
        }
    };

    return (
        <header className="header">

            <div className="nav-left">
                <div className="header-nav" onClick={onMenuClick}>
                    <div className="header-nav-line1"></div>
                    <div className="header-nav-line2"></div>
                    <div className="header-nav-line3"></div>
                </div>

                <Link to="/" style={{display:"flex", alignItems:"center"}}>
                    <img className="nav-logo" src="/header_logo.png"></img>
                </Link>
            </div>

            <div className="nav-right">

                <div className={`header-link ${location.pathname === '/invitation' ? 'active' : ''}`} onClick={() => handleLinkClick('/invitation')}>
                    <img src="/header-menu-icon-invitation.png" className="nav-link-img" alt="Invite" />
                    เชิญเพื่อน
                </div>

                <div className={`header-link ${location.pathname === '/commission' ? 'active' : ''}`} onClick={() => handleLinkClick('/commission')}>
                    <img src="/commission_temp.png" className="nav-link-img" alt="Commission" />
                    คืนยอดเสีย
                </div>

                <div className={`header-link ${location.pathname === '/ranking' ? 'active' : ''}`} onClick={() => handleLinkClick('/ranking')}>
                    <img src="/ranking.png" className="nav-link-img" alt="Ranking" />
                    อันดับ
                </div>

                <div className={`header-link ${location.pathname === '/luckywheel' ? 'active' : ''}`} onClick={() => handleLinkClick('/luckywheel')}>
                    <img src="/wheel.png" className="nav-link-img" alt="Lucky Wheel" />
                    กงล้อ
                </div>

                <div className={`header-link ${location.pathname === '/promotion' ? 'active' : ''}`} onClick={() => handleLinkClick('/promotion')}>
                    <img src="/promotion_icon.png" className="nav-link-img" alt="Promotion" />
                    โปรโมชั่น
                </div>

                {auth.isAuthenticated ? 
                    (
                        <>
                            
                            <div className="nav-balance-container">
                                <p className="nav-balance-container-text">{auth?.userData?.telephoneNumber ?? ''}</p>
                                <div className="account-balance-container">
                                    <img src="/coin.png"></img>
                                    <p>{auth?.userData?.wallet?.balance.toFixed(2) ?? 0.00}</p>
                                    <ReloadOutlined className="account-balance-reload"/>
                                </div>
                            </div>

                            <button onClick={onUserDetail} className="nav-rank-button">
                                <img src={getRankImage(auth.userData?.rank?.currentRank)} className="nav-user-img"></img>
                            </button>
                        </>
                    ) : 
                    (
                        <>
                            <button onClick={onLoginClick} className="nav-login-li">
                                เข้าสู่ระบบ
                            </button>
                        </>
                    )
                }
            
            </div>
        </header>
    );
}

export default Header;