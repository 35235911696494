import React, {useState, useEffect} from 'react';
import { useAuth } from '../../../AuthContext';
import './UserDetail.css';
import { useTranslation } from 'react-i18next';
import { Button, Input, Form, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

function Withdraw({onRedirect}){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState('PICK_METHOD');
    const [form] = Form.useForm();
    const [accountData, setAccountData] = useState(null);
    const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.userData) {
            setAccountData(auth.userData);
            const initialFormValues = {
                username: auth.userData.telephoneNumber,
                balance: auth.userData.wallet.balance,
                memberId: auth.userData.memberId,
            };

            if (auth.userData.mainBankAccount && auth.userData.mainBankAccount.id !== null) {
                initialFormValues.bankAccountId = auth.userData.mainBankAccount.id;
            }
        
            form.setFieldsValue(initialFormValues);
            setIsBankAccountAvailable(auth.userData.bankAccounts.length > 0);
        }
    }, [auth]);

    const onFinish = async(values) => {
        console.log('Received values:', values);

        if (!auth.token) {
            console.error("No token available");
            return;
        }

        const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(apiUrl + "/member/withdraw/request?username=" + auth.username+"&amount="+values.amount, requestOptions);
            if (response.ok) {
                const data = await response.json();
                onRedirect("WITHDRAW_SUCCESS");
            } else {
                console.error("Failed to fetch URL");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const renderContent = () => {
        switch (currentStep) {
            case 'PICK_METHOD':
                return (
                    <div className="account-content-container">
                        <h1 className="account-modal-title">{t('please_select_withdraw_method')}</h1>
                        
                        <div className="account-content">
                            <div style={{display: 'flex'}}>
                                <Button className="deposit-method-button" onClick={() => setCurrentStep("LOCAL_BANK_TRANSFER")}>
                                    Local Bank Transfer
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            case 'LOCAL_BANK_TRANSFER':
                return (
                    <div className="account-content-container">
                        <h1 className="account-modal-title">{t('enter_withdraw_amount')}</h1>
                        <Form form={form} 
                            onFinish={onFinish}
                            className="deposit-form" layout="vertical" 
                            initialValues={{
                            enable: true
                        }}>
                            <h1 className="account-modal-title">โปรดระบุจำนวนเงิน</h1>
                            <Form.Item name="amount" label={t('balance')} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="bankAccountId" label={t('select_bank_to_withdraw')} rules={[{ required: true }]}>
                                <Select placeholder="Select Bank Account" disabled={!isBankAccountAvailable}>
                                {isBankAccountAvailable ? (
                                    accountData.bankAccounts.map(account => (
                                    <Select.Option
                                        key={account.id}
                                        value={account.id}
                                        disabled={account.id === accountData.mainBankAccount.id}
                                    >
                                        {account.bankName} - {account.accountNumber} - {account.fullName}
                                    </Select.Option>
                                    ))
                                ) : (
                                    <Select.Option disabled>Loading bank accounts...</Select.Option>
                                )}
                                </Select>
                            </Form.Item>

                            <Form.Item style={{justifySelf: 'center', alignSelf: 'center'}}>
                                <Button type="primary" className="deposit-button" htmlType="submit">
                                {t('submit')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                );
        }
    }

    return (<>
        {renderContent()}
    </>);
}

export default Withdraw;