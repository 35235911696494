import React from 'react';
import './ProviderSection.css';
import { useGameDataContext, setSelectedProvider } from '../../GameDataContext';

function ProviderSection() {
    const { state, dispatch } = useGameDataContext();
    const { providers, selectedProvider } = state;
  
    const handleProviderClick = (providerName) => {
        console.log()
      setSelectedProvider(dispatch, providerName); // Set selectedProvider, which will automatically trigger fetching games in context
    };
  
    return (
      <div className="provider-section">
        <h1 className="provider-title">Winner ให้บริการค่ายเกมชั้นนำ ผ่านทางระบบไลฟ์สด บาคาร่า ได้รับความนิยมอันดับ 1</h1>
        <div className="provider-container">
          {providers.map((provider) => (
            <div className="provider-button-container" key={provider.id}>
              <div className="provider-button">
                <img
                  className={`provider-image ${selectedProvider === provider.name ? "selected" : ""}`}
                  src={provider.logoUrl}
                  alt={provider.name}
                />
                <button className="provider-play-button"
                onClick={() => handleProviderClick(provider.name)}>เข้าเล่น</button>
              </div>
              <p className="provider-button-text">{provider.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  export default ProviderSection;