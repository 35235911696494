import React, { useState } from 'react';
import './AccountModal.css';
import { useAuth } from '../../AuthContext';
import Account from './AccountModal/Account';
import Deposit from './AccountModal/Deposit';
import Withdraw from './AccountModal/Withdraw';
import TransactionHistory from './AccountModal/TransactionHistory';
import PromotionHistory from './AccountModal/PromotionHistory';
import CurrentPromotion from './AccountModal/CurrentPromotion';

function AccountModal({ visible, onClose }) {
  const [errorMessage, setErrorMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedPage, setSelectedPage] = useState('ACCOUNT');

  const { auth, logout } = useAuth();

  if (!visible) return null;

  const handlePageSelect = (page) => {
    setSelectedPage(page);
  }

  const handleLogout = () => {
    setSelectedPage('ACCOUNT');
    onClose();
    logout();
  }

  const renderContent = () => {
    switch (selectedPage) {
      case 'ACCOUNT':
        return <Account />;
      case 'DEPOSIT':
        return <Deposit />;
      case 'WITHDRAW':
        return <Withdraw />;
      case 'TRANSACTION_HISTORY':
        return <TransactionHistory />;
      case 'PROMOTION_HISTORY':
        return <PromotionHistory />;
      case 'JOINED_PROMOTIONS':
        return <CurrentPromotion />;
      default:
        return null;
    }
  }

  return (
    <>
      <div className="modal-overlay"/>
        
        <div className="account-modal-container">
            <button className="account-button-close" onClick={onClose}>&times;</button>

            <div className="account-modal-sidebar">
              <div className="account-modal-sidebar-content">
                <button 
                  className={`account-modal-sidebar-button ${selectedPage === 'ACCOUNT' ? 'active' : ''}`} 
                  onClick={() => handlePageSelect('ACCOUNT')}
                >
                  <img src="/member_light.png"/>ข้อมูลบัญชี
                </button>
                <button 
                  className={`account-modal-sidebar-button ${selectedPage === 'DEPOSIT' ? 'active' : ''}`} 
                  onClick={() => handlePageSelect('DEPOSIT')}
                >
                  <img src="/member_light.png"/>ฝากเงิน
                </button>
                <button 
                  className={`account-modal-sidebar-button ${selectedPage === 'WITHDRAW' ? 'active' : ''}`} 
                  onClick={() => handlePageSelect('WITHDRAW')}
                >
                  <img src="/member_light.png"/>ถอนเงิน
                </button>
                <button 
                  className={`account-modal-sidebar-button ${selectedPage === 'TRANSACTION_HISTORY' ? 'active' : ''}`} 
                  onClick={() => handlePageSelect('TRANSACTION_HISTORY')}
                >
                  <img src="/member_light.png"/>ประวัติธุรกรรม
                </button>
                <button 
                  className={`account-modal-sidebar-button ${selectedPage === 'PROMOTION_HISTORY' ? 'active' : ''}`} 
                  onClick={() => handlePageSelect('PROMOTION_HISTORY')}
                >
                  <img src="/member_light.png"/>ประวัติโปรโมชั่น
                </button>
                <button 
                  className={`account-modal-sidebar-button ${selectedPage === 'JOINED_PROMOTIONS' ? 'active' : ''}`} 
                  onClick={() => handlePageSelect('JOINED_PROMOTIONS')}
                >
                  <img src="/member_light.png"/>โปรโมชั่นที่เข้าร่วม
                </button>
                <button 
                  className={`account-modal-sidebar-button ${selectedPage === 'LOGOUT' ? 'active' : ''}`} 
                  onClick={() => handleLogout()}
                >
                  <img src="/logout_icon.png"/>ออกจากระบบ
                </button>
              </div>
              
            </div>

            <div className="account-modal-content-container">
                <div className="account-modal-content">
                  {renderContent()}
                </div>
            </div>
        </div>
    </>
    );
}

export default AccountModal;