import React, { useState, useEffect, useMemo } from 'react';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification, message  } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import DataTable from '../../table/DataTable';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import './Account.css';
import { useTranslation } from 'react-i18next';

function Account(){

    const { auth } = useAuth();
    const [form] = Form.useForm();
    const [accountData, setAccountData] = useState(null);
    const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);
    const [selectedPage, setSelectedPage] = useState('ACCOUNT');
    const [data, setData] = useState([]);
    const [selectedBankAccount, setSelectedBankAccount] = useState(null);
    const { t } = useTranslation();

    const columns = useMemo(() => [
        { Header: 'Bank', accessor: 'bankName' },
        { Header: 'Bank number', accessor: 'accountNumber' },
        { Header: 'Full Name', accessor: 'fullName' },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: 'id',
            Cell: ({ value }) => (
                <div>
                    <EditOutlined onClick={() => handleEdit(value)} style={{ color: 'blue', marginRight: 12, cursor: 'pointer' }} />
                </div>
            )
        }
    ], []);

    useEffect(() => {
        if(auth.userData){
            setAccountData(auth.userData);
        }
    }, [auth]);

    useEffect(() => {
        if (accountData) {
            const initialFormValues = {
                username: accountData.telephoneNumber,
                balance: accountData.wallet.balance,
                memberId: accountData.memberId,
            };
    
            if (accountData.mainBankAccount && accountData.mainBankAccount.id !== null) {
                initialFormValues.bankAccountId = accountData.mainBankAccount.id;
            }
        
            form.setFieldsValue(initialFormValues);
            setIsBankAccountAvailable(accountData.bankAccounts.length > 0);
            setData(accountData.bankAccounts);
          }
    }, [accountData, form]);

    const changeMainBankAccount = async(values) => {
        console.log('Form values:', JSON.stringify(values));
        const method = "PUT";

        var apiUrl = `${process.env.REACT_APP_API_URL}/member/bankaccount/setmain`;
        
        const requestOptions = {
            method,
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
            body: JSON.stringify(values)
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            message.success('Bank account set successfully!');

        } catch (error) {
            console.error('Error posting member:', error);
        }
    }

    const handleEdit = (id) => {
        setSelectedBankAccount(id);
        setSelectedPage('EDIT_BANK_ACCOUNT');
    };

    const renderContent = () => {
        switch (selectedPage) {
          case 'ACCOUNT':
            return (
                <div className="account-content-container">
                    <h1 className="account-modal-title">{t('account')}</h1>
        
                    <div className="account-content">
                        <Form form={form} 
                            className="account-form" layout="vertical" 
                            onFinish={changeMainBankAccount}
                            initialValues={{
                            enable: true,
                        }}>
                            <Form.Item
                                name="memberId"
                                hidden
                            >
                                <Input/>
                            </Form.Item>
        
                            <Form.Item name="username" label="Username" rules={[{ required: true }]} dis>
                                <Input disabled={true}/>
                            </Form.Item>
        
                            <Form.Item name="balance" label="Balance" rules={[{ required: true }]}>
                                <Input disabled={true}/>
                            </Form.Item>
        
                            <Form.Item name="bankAccountId" label="Default Bank Account" rules={[{ required: true }]}>
                                <Select placeholder="Select Bank Account" disabled={!isBankAccountAvailable}>
                                {isBankAccountAvailable ? (
                                    accountData.bankAccounts.map(account => (
                                    <Select.Option
                                        key={account.id}
                                        value={account.id}
                                        disabled={account.id === accountData.mainBankAccount.id}
                                    >
                                        {account.bankName} - {account.accountNumber} - {account.fullName}
                                    </Select.Option>
                                    ))
                                ) : (
                                    <Select.Option disabled>Loading bank accounts...</Select.Option>
                                )}
                                </Select>
                            </Form.Item>
                            
                            <Form.Item>
                                <Button onClick={() => setSelectedPage('MANAGE_BANK_ACCOUNT')} type="primary" danger>Manage Bank Account</Button>
                            </Form.Item>
        
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form>
                    </div>
                </div>
            );
          case 'MANAGE_BANK_ACCOUNT':
            return (
                <div className="account-content-container">
                    <h1 className="account-modal-title">Manage Bank Account</h1>

                    <div className="user-detail-data">
                        <Form
                            form={form}
                            className="account-form"
                            layout="vertical"
                            initialValues={{ enable: true }}
                        >
                            <DataTable columns={columns} data={data} />
                            <br></br>
                            <Link to="/user/detail/managebank/add" style={{marginTop:"20"}}>
                                <Button type="primary" danger>Add Bank Account</Button>
                            </Link>
                        </Form>
                    </div>
                </div>
                );
        }
    }

    return (<>
        {renderContent()}
    </>);
}

export default Account;