import React, { useState } from 'react';
import './LoginModal.css';
import { Input } from 'antd';
import { useAuth } from '../../AuthContext';
import { useTranslation } from 'react-i18next';

function LoginModal({ visible, onClose, onRegisterClick }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const { t } = useTranslation();

  const handleLogin = async (event) => {

    event.preventDefault();
    
    const credentials = { username, password };

    const raw = JSON.stringify({
        "telephoneNumber": credentials.username,
        "password": credentials.password
    });

    try {
      const response = await fetch(apiUrl+'/api/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: raw
      });

      const data = await response.json();

      if (!response.ok) {
        setErrorMessage(data.message);
      }
      else{
        login(data.data.jwt, credentials.username);
        setUsername('');
        setPassword('');
        setErrorMessage('');
        onClose();
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleRegisterClick = () => {
    setUsername('');
    setPassword('');
    setErrorMessage('');
    onClose();
    onRegisterClick();
  }

  if (!visible) return null;

  return (
    <>
      <div className="modal-overlay"/>
  
        <div className="login-modal-container">
  
          <div className="login-header-container">
            <button className="login-header-button" onClick={handleRegisterClick}>{t('register')}</button>
            <button className="login-header-button-active">{t('login')}</button>
          </div>
  
          <button className="login-button-close" onClick={onClose}>&times;</button>
  
          <div className="login-modal-content">
  
            {errorMessage && <p className="login-error-message">{errorMessage}</p>}
  
            <div className="login-input-container">
              <img className="login-button-image" src="/phone_icon.png"></img>
              <Input 
                className="login-username-input"
                placeholder="Username" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            
            <div className="login-input-container">
              <img className="login-button-image" src="/lock_icon.png"></img>
              <Input.Password
                className="login-username-input"
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
            </div>
  
            <div className="login-modal-no-account-container">
              <a className="login-modal-no-account-link" onClick={handleRegisterClick}>{t('forgot_password')}</a>
            </div>
  
            <button className="login-button" onClick={handleLogin}>
            {t('login')}
            </button>
  
          </div>
      </div>
    </>
    );
}

export default LoginModal;