import '../../App.css';

import Header from '../layout/Header';
import MainSideBar from '../layout/MainSideBar';
import AnnouncementBar from '../layout/AnnouncementBar';
import FooterWithProvider from '../layout/FooterWithProvider';
import Footer from '../layout/Footer';
import React, { useState, useContext, useEffect } from 'react';

import PromotionSection from '../layout/PromotionSection';

import LoginModal from '../common/LoginModal';
import { useAuth } from '../../AuthContext';

import RegisterModal from '../common/RegisterModal';
import AccountModal from '../common/AccountModal';
import MenuModal from '../common/MenuModal';
import RankModal from '../modal/RankModal';

import { ThemeContext } from '../../ThemeContext';

function PromotionPage(){
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isMenuModalVisible, seIsMenuModalVisible] = useState(false);
  const { auth } = useAuth();

  const showLoginModal = () => setIsLoginModalVisible(true);
  const cancelLoginModal = () => setIsLoginModalVisible(false);

  const showRegisterModal = () => setIsRegisterModalVisible(true);
  const cancelRegisterModal = () => setIsRegisterModalVisible(false);

  const showAccountModal = () => setIsAccountModalVisible(true);
  const cancelAccountModal = () => setIsAccountModalVisible(false);

  const showMenuModal = () => seIsMenuModalVisible(true);
  const cancelMenuModal = () => seIsMenuModalVisible(false);

  const handleAccountClick = () => {
    showAccountModal(true);
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const { theme } = useContext(ThemeContext);
  const [backgroundUrl, setBackgroundUrl] = useState('');

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('imageSettings'));
    if (settings) {
      // Find the correct setting based on type, theme, and device (fixed to 'desktop')
      const layoutSetting = settings.find(setting => 
        setting.type === 'main_background' && 
        setting.theme === theme &&
        setting.device === 'desktop' // Fixed device to 'desktop'
      );
      
      if (layoutSetting && layoutSetting.uploadImage) {
        // Construct the correct URL for the background image
        const imageUrl = `${apiUrl}${layoutSetting.uploadImage.url.replace(/\\/g, '/')}`;
        setBackgroundUrl(imageUrl);
      }
    }
  }, [theme, apiUrl]);

  const layoutStyle = {
    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh'
  };
  
  return (
    <>
      <Header onLoginClick={showLoginModal} onRegisterClick={showRegisterModal} 
              onUserDetail={handleAccountClick}
              onMenuClick={showMenuModal}
      />

      <MainSideBar/>

      <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} onRegisterClick={showRegisterModal}/>
      <RegisterModal visible={isRegisterModalVisible} onClose={cancelRegisterModal} onLogin={showLoginModal}/>
      <AccountModal visible={isAccountModalVisible} onClose={cancelAccountModal}/>

      <div className="layout" style={layoutStyle}>
        <main className="main-content">

            <AnnouncementBar/>

            <h1 className="page-title" style={{textAlign:"center", width:"100%"}}>โปรโมชั่นคาสิโน Winner พร้อมส่งต่อความคุ้มค่าให้แบบไม่อั้น</h1>
            
            <PromotionSection/>
            
        </main>
        
        <RankModal/>
        <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal}/>
        
        <FooterWithProvider/>
        <Footer />
      </div>
      
    </>
  );
}

export default PromotionPage;