import React, {useState} from 'react';
import { useAuth } from '../../../AuthContext';
import './UserDetail.css';
import './Deposit.css';
import { useTranslation } from 'react-i18next';
import { Button, Input, Form } from 'antd';

function Deposit(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState('PICK_METHOD');
    const [form] = Form.useForm();

    const onFinish = async(values) => {
        console.log('Received values:', values);

        if (!auth.token) {
            console.error("No token available");
            return;
        }

        const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(apiUrl + "/member/deposit/getpaypay89?username=" + auth.username+"&amount="+values.amount+"&promoCode="+values.promoCode, requestOptions);
            if (response.ok) {
                const url = await response.text();
                console.log("URL received:", url);

                // Create an anchor element for redirection
                const a = document.createElement('a');
                a.href = url;
                a.rel = 'noopener noreferrer';
                
                // console.log("Attempting to redirect to:", url);
                a.click();
                // console.log("Redirection successful to:", url);
            } else {
                console.error("Failed to fetch URL");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const renderContent = () => {
        switch (currentStep) {
            case 'PICK_METHOD':
                return (
                    <div className="account-content-container">
                        <h1 className="account-modal-title">{t('please_select_deposit_method')}</h1>
                        
                        <div className="account-content">
                            <div style={{display: 'flex'}}>
                                <img src="/paypay89_button.png" className="account-select-method-image" onClick={() => setCurrentStep("PAYPAY89")}/>
                            </div>
                        </div>
                    </div>
                );
            case 'PAYPAY89':
                return (
                    <div className="account-content-container">
                        <h1 className="account-modal-title">{t('enter_deposit_amount')}</h1>
                        <Form form={form} 
                            onFinish={onFinish}
                            className="deposit-form" layout="vertical" 
                            initialValues={{
                            enable: true
                        }}>
                            <Form.Item name="amount" label={t('balance')} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="promoCode" label={t('promotion_code')} rules={[{ required: false }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item style={{justifySelf: 'center', alignSelf: 'center'}}>
                                <Button type="primary" className="deposit-button" htmlType="submit">
                                    {t('submit')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                );
        }
    }

    return (<>
        {renderContent()}
    </>);
}

export default Deposit;