import '../../App.css';

import Header from '../layout/Header';
import MainSideBar from '../layout/MainSideBar';
import AnnouncementBar from '../layout/AnnouncementBar';
import FooterWithProvider from '../layout/FooterWithProvider';
import Footer from '../layout/Footer';

import { useModal } from '../modal/ModalContext';
import React, { useEffect, useState, useContext, useRef } from 'react';
import SlideBar from '../layout/SlideBar';
import ProviderSection from '../layout/ProviderSection';
import GameSection from '../layout/GameSection';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LoginModal from '../common/LoginModal';
import { useAuth } from '../../AuthContext';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import RegisterModal from '../common/RegisterModal';
import AccountModal from '../common/AccountModal';
import MenuModal from '../common/MenuModal';
import RankModal from '../modal/RankModal';
import { useGameDataContext } from '../../GameDataContext';
import { ThemeContext } from '../../ThemeContext';
import { m } from 'framer-motion';

function HomePage({ imageSettingsLoaded }) {
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isMenuModalVisible, seIsMenuModalVisible] = useState(false);
  const { login, auth } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const { theme } = useContext(ThemeContext);
  const [backgroundUrl, setBackgroundUrl] = useState('');

  useEffect(() => {
    console.log("imageSettingsLoaded = "+imageSettingsLoaded);
    if (imageSettingsLoaded) {
      const settings = JSON.parse(localStorage.getItem('imageSettings'));
      console.log(settings);
      if (settings) {
        const layoutSetting = settings.find(setting =>
          setting.type === 'main_background' &&
          setting.theme === theme &&
          setting.device === 'desktop'
        );

        if (layoutSetting && layoutSetting.uploadImage) {
          const imageUrl = `${apiUrl}${layoutSetting.uploadImage.url.replace(/\\/g, '/')}`;
          setBackgroundUrl(imageUrl);
        }
      }
    }
  }, [theme, apiUrl, imageSettingsLoaded]);

  const layoutStyle = {
    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    minHeight: '100vh',
  };

  const { state } = useGameDataContext();
  const { selectedProvider } = state; // Access selectedProvider from state

  const userDetailWindowRef = useRef(null);
  const { openModal } = useModal();
  const [searchParams] = useSearchParams();

  const showLoginModal = () => setIsLoginModalVisible(true);
  const cancelLoginModal = () => setIsLoginModalVisible(false);

  const showRegisterModal = () => setIsRegisterModalVisible(true);
  const cancelRegisterModal = () => setIsRegisterModalVisible(false);

  const showAccountModal = () => setIsAccountModalVisible(true);
  const cancelAccountModal = () => setIsAccountModalVisible(false);

  const showMenuModal = () => seIsMenuModalVisible(true);
  const cancelMenuModal = () => seIsMenuModalVisible(false);

  useEffect(() => {
    const fetchAffiliateCode = async () => {
      const affiliateCode = searchParams.get('code');
      if (affiliateCode) {
        let affiliateApiUrl = apiUrl + "/api/affiliate/click?code=" + affiliateCode;
        Cookies.set('winner_affiliate_code', affiliateCode, { expires: 14, path: '/' });
  
        try {
          const response = await fetch(affiliateApiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error("affiliateCode Click :", error);
        }
      }
    };
  
    fetchAffiliateCode();
  }, [searchParams, apiUrl]);

  const handleSupportClick = async () => {
    if (!auth.token) {
      console.error("No token available");
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(apiUrl + "/api/secure-data", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  const handleMemberRankClick = () => {
    openModal('rank', auth.userData);
  };

  const handleAccountClick = () => {
    showAccountModal(true);
  };
  
  return (
    <>
      <Header 
        onLoginClick={showLoginModal} 
        onRegisterClick={showRegisterModal} 
        onSupportClick={handleSupportClick} 
        onUserDetail={handleAccountClick}
        onMemberRank={handleMemberRankClick} 
        onMenuClick={showMenuModal}
      />

      <MainSideBar />

      <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} onRegisterClick={showRegisterModal} />
      <RegisterModal visible={isRegisterModalVisible} onClose={cancelRegisterModal} onLogin={showLoginModal} />
      <AccountModal visible={isAccountModalVisible} onClose={cancelAccountModal} />

      <div className="layout" style={layoutStyle}>
        <main className="main-content">
          <AnnouncementBar />
          <h1 className="page-title">Winner บาคาร่า คาสิโนออนไลน์ เสือมังกร โปรแรงสุดในไทย ฝากถอนออโต้</h1>
          <SlideBar onLogin={showLoginModal}/>
          {!selectedProvider && <ProviderSection />}
          {selectedProvider && <GameSection />}
        </main>

        <RankModal />
        <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal} />
        <FooterWithProvider />
        <Footer />
      </div>
    </>
  );
}

export default HomePage;
