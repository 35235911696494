import '../../App.css';
import './LuckyWheelPage.css';
import Header from '../layout/Header';
import MainSideBar from '../layout/MainSideBar';
import AnnouncementBar from '../layout/AnnouncementBar';
import FooterWithProvider from '../layout/FooterWithProvider';
import Footer from '../layout/Footer';
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import LoginModal from '../common/LoginModal';
import { useAuth } from '../../AuthContext';

import RegisterModal from '../common/RegisterModal';
import AccountModal from '../common/AccountModal';
import MenuModal from '../common/MenuModal';
import RankModal from '../modal/RankModal';
import WheelGameModal from '../common/WheelGameModal';

import { ThemeContext } from '../../ThemeContext';

function LuckyWheelPage() {
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [isMenuModalVisible, seIsMenuModalVisible] = useState(false);
  
  const [profile, setProfile] = useState(null);
  const [prizes, setPrizes] = useState([]);
  const [info, setInfo] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [isCountdownComplete, setIsCountdownComplete] = useState(false); // New state to track countdown completion
  const [isWheelModalVisible, setIsWheelModalVisible] = useState(false);

  const { auth } = useAuth();

  const showWheelModal = () =>  setIsWheelModalVisible(true);
  const cancelWheelModal = () => setIsWheelModalVisible(false);

  const showLoginModal = () => setIsLoginModalVisible(true);
  const cancelLoginModal = () => setIsLoginModalVisible(false);

  const showRegisterModal = () => setIsRegisterModalVisible(true);
  const cancelRegisterModal = () => setIsRegisterModalVisible(false);

  const showAccountModal = () => setIsAccountModalVisible(true);
  const cancelAccountModal = () => setIsAccountModalVisible(false);

  const showMenuModal = () => seIsMenuModalVisible(true);
  const cancelMenuModal = () => seIsMenuModalVisible(false);

  const handleAccountClick = () => {
    showAccountModal(true);
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const { theme } = useContext(ThemeContext);
  const [backgroundUrl, setBackgroundUrl] = useState('');

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('imageSettings'));
    if (settings) {
      // Find the correct setting based on type, theme, and device (fixed to 'desktop')
      const layoutSetting = settings.find(setting => 
        setting.type === 'main_background' && 
        setting.theme === theme &&
        setting.device === 'desktop' // Fixed device to 'desktop'
      );
      
      if (layoutSetting && layoutSetting.uploadImage) {
        // Construct the correct URL for the background image
        const imageUrl = `${apiUrl}${layoutSetting.uploadImage.url.replace(/\\/g, '/')}`;
        setBackgroundUrl(imageUrl);
      }
    }
  }, [theme, apiUrl]);

  const layoutStyle = {
    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh'
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      fetchLuckyWheelProfile();
    }
  }, [auth]);

  useEffect(() => {
    fetchLuckyWheelInfo();
    fetchLuckyWheelPrize();
  }, []);

  useEffect(() => {
    let timer;
    if (profile && profile.nextAttemptTime) {
      const nextAttemptTime = new Date(profile.nextAttemptTime).getTime();

      const updateCountdown = () => {
        const now = new Date().getTime();
        const distance = nextAttemptTime - now;

        if (distance > 0) {
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          setCountdown(`${hours}h ${minutes}m ${seconds}s`);
        } else {
          setCountdown('Available now!');
          setIsCountdownComplete(true); // Set flag to true when countdown reaches 0
        }
      };

      timer = setInterval(updateCountdown, 1000);

      return () => clearInterval(timer);
    }
  }, [profile]);

  const fetchLuckyWheelInfo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/member/luckywheel/info`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      setInfo(response.data); // Set the info data
    } catch (error) {
      console.error('Error fetching Lucky Wheel Info:', error);
    }
  };

  const fetchLuckyWheelPrize = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/member/luckywheel/prize`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      setPrizes(response.data); // Set the prizes data
    } catch (error) {
      console.error('Error fetching Lucky Wheel Prizes:', error);
    }
  };

  const fetchLuckyWheelProfile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/member/luckywheel/profile`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      setProfile(response.data); // Set the profile data
    } catch (error) {
      console.error('Error fetching Lucky Wheel Profile:', error);
    }
  };

  const spinNow = () => {
    showWheelModal();
  };

  return (
    <>
      <Header onLoginClick={showLoginModal} onRegisterClick={showRegisterModal} 
              onUserDetail={handleAccountClick}
              onMenuClick={showMenuModal}
      />

      <MainSideBar />

      <LoginModal visible={isLoginModalVisible} onClose={cancelLoginModal} onRegisterClick={showRegisterModal} />
      <RegisterModal visible={isRegisterModalVisible} onClose={cancelRegisterModal} onLogin={showLoginModal} />
      <AccountModal visible={isAccountModalVisible} onClose={cancelAccountModal} />

      <div className="layout" style={layoutStyle}>
        <main className="main-content">
          <AnnouncementBar />

          <h1 className="center-page-title" style={{ textAlign: "center", width: "100%" }}>
            กงล้อมหาสนุก ลุ้นรับ iPhone ฟรี
          </h1>

          {profile && (
            <div className="profile-section">
              <h2>Profile</h2>
              <p><strong>Remaining Attempts:</strong> {profile.remainingAttempts}</p>
              <p><strong>Max Attempts:</strong> {profile.maxAttempts}</p>
              <p><strong>Next Attempt Time:</strong> {countdown}</p>

              {isCountdownComplete ? (
                <button onClick={spinNow}>Spin Now!</button>
                ) : (
                <button className="disabled" disabled>
                  Spin Not Ready
                </button>
              )}
            </div>
          )}

          {info && (
            <div className="info-section">
              <h2>Lucky Wheel Info</h2>
              <p>{info.titleTh}</p>
              <p>{info.descriptionTh}</p>
            </div>
          )}

          <div className="prizes-section">
            <h2>Prizes</h2>
            {prizes.length > 0 ? (
              <ul>
                {prizes.map((prize, index) => (
                  <li key={index}>{prize.titleTh} - {prize.descriptionTh} - โอกาส {prize.chance}</li>
                ))}
              </ul>
            ) : (
              <p>No prizes available at the moment.</p>
            )}
          </div>
        </main>
        
        <RankModal />
        <MenuModal visible={isMenuModalVisible} onClose={cancelMenuModal} />
        
        <WheelGameModal visible={isWheelModalVisible} onClose={cancelWheelModal} />
        
        <FooterWithProvider />
        <Footer />
      </div>
    </>
  );
}

export default LuckyWheelPage;
