import React, {useState} from 'react';
import './MainSideBar.css';
import { useGameDataContext, setSelectedCategory, setSelectedProvider } from '../../GameDataContext';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

function MainSideBar() {

    const { state, dispatch } = useGameDataContext();
    const { t } = useTranslation();
    const { selectedCategory } = state;
    const navigate = useNavigate();
    const location = useLocation();

    const handleCategoryClick = (category) => {
        setSelectedCategory(dispatch, category); // Update selected category
        setSelectedProvider(dispatch, null); // Reset selected provider

        // If not on the home ('/') route, navigate to '/'
        if (location.pathname !== '/') {
            navigate('/');
        }
    };

    return (
        <aside className="sidebar">
            <button className={`sidebar-button ${selectedCategory === "SLOT" ? "active" : ""}`} 
                    onClick={() => handleCategoryClick("SLOT")}>
                <img src="/slot_3d.png"></img>
                <div className="sidebar-button-text" onClick={() => handleCategoryClick("CASINO")}>
                    <p>SLOT</p>
                    <p>{t('slot')}</p>
                </div>
            </button>

            <button className={`sidebar-button ${selectedCategory === "CASINO" ? "active" : ""}`} 
                    onClick={() => handleCategoryClick("CASINO")}>
                <img src="/casino_3d.png"></img>
                <div className="sidebar-button-text">
                    <p>CASINO</p>
                    <p>{t('casino')}</p>
                </div>
            </button>

            <button className={`sidebar-button ${selectedCategory === "SPORT" ? "active" : ""}`} 
                    onClick={() => handleCategoryClick("SPORT")}>
                <img src="/sports_3d.png"></img>
                <div className="sidebar-button-text">
                    <p>SPORT</p>
                    <p>{t('sports')}</p>
                </div>
            </button>

            <button className={`sidebar-button ${selectedCategory === "CARD" ? "active" : ""}`} 
                    onClick={() => handleCategoryClick("CARD")}>
                <img src="/cards_3d.png"></img>
                <div className="sidebar-button-text">
                    <p>CARD</p>
                    <p>{t('cards')}</p>
                </div>
            </button>

            <button className={`sidebar-button ${selectedCategory === "FISH" ? "active" : ""}`} 
                    onClick={() => handleCategoryClick("FISH")}>
                <img src="/fishing_3d.png"></img>
                <div className="sidebar-button-text">
                    <p>FISHING</p>
                    <p>{t('fishing')}</p>
                </div>
            </button>

            <button className={`sidebar-button ${selectedCategory === "POKER" ? "active" : ""}`} 
                    onClick={() => handleCategoryClick("POKER")}>
                <img src="/poker_3d.png"></img>
                <div className="sidebar-button-text">
                    <p>POKER</p>
                    <p>{t('poker')}</p>
                </div>
            </button>

            <button className={`sidebar-button ${selectedCategory === "KENO" ? "active" : ""}`} 
                    onClick={() => handleCategoryClick("KENO")}>
                <img src="/keno_3d.png"></img>
                <div className="sidebar-button-text">
                    <p>KENO</p>
                    <p>{t('keno')}</p>
                </div>
            </button>

            <button className={`sidebar-button ${selectedCategory === "TRADE" ? "active" : ""}`} 
                    onClick={() => handleCategoryClick("TRADE")}>
                <img src="/trade_3d.png"></img>
                <div className="sidebar-button-text">
                    <p>TRADING</p>
                    <p>{t('trading')}</p>
                </div>
            </button>

            <button className={`sidebar-button ${selectedCategory === "ESPORT" ? "active" : ""}`} 
                    onClick={() => handleCategoryClick("ESPORT")}>
                <img src="/esports_3d.png"></img>
                <div className="sidebar-button-text">
                    <p>ESPORT</p>
                    <p>{t('esports')}</p>
                </div>
            </button>

            <button className={`sidebar-button ${selectedCategory === "COCK" ? "active" : ""}`} 
                    onClick={() => handleCategoryClick("COCK")}>
                <img src="/cock_3d.png"></img>
                <div className="sidebar-button-text">
                    <p>COCK</p>
                    <p>{t('cockfight')}</p>
                </div>
            </button>

            <div className="sidebar-contact">
                <img src="/contact-us.png" className="sidebar-contact-image"></img>
                <img src="/contact-us-line.png" className="sidebar-contact-icon"></img>
            </div>
        </aside>
    );
}

// {categories.map((category, index) => (
// <li key={category.id}>
//     <button onClick={() => handleCategoryClick(category)} 
//     className={`category-button ${selectedCategory === category.name ? "selected" : ""}`}
//     style={{backgroundImage: 'url(button_box.png)', backgroundSize: 'cover'}} 
//     key={category.id}>
//         {category.name}
//     </button>
// </li>
// ))}

export default MainSideBar;