import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import Header from '../../layout/UserDetail/Header';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification  } from 'antd';
import './UserDetail.css';
import './Deposit.css';

function DepositStepOne(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)'}}>
            <Header/>
            <div className="user-detail-data-container">

                <h1 style={{color: 'white'}}>Deposit</h1>

                <div className="user-detail-data">
                    <h1>Select Deposit Method</h1>
                    <div style={{display: 'flex'}}>
                        
                        <Link to="/user/detail/deposit/paypay89" className="deposit-method-button">
                            <img src="/paypay89_button.png" style={{height: '60px', width: '200px', borderRadius: '10px', cursor:'pointer'}}></img>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default DepositStepOne;