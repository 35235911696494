import React, { useState, useContext } from 'react';
import { useAuth } from '../../AuthContext';
import './MenuModal.css';
import './PromotionModal.css';
import { useTranslation } from 'react-i18next';

const PromotionModal = ({ visible, promotion, onClose }) => {
  const { auth } = useAuth();

  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <>
        <div className="modal-overlay"/>

        <div className="menu-modal-container">
            <button className="menu-button-close" onClick={onClose}>&times;</button>

            <div className="promotion-modal-content">

                {promotion ? (
                    <>
                        <h2 className="promotion-title">{promotion.name}</h2>
                        <img className="promotion-modal-image" src={promotion.imgUrlHorizontal} alt={promotion.name} />
                        <p className="promotion-description">{promotion.description}</p>

                        <button className="promotion-modal-button">รับโปรโมชั่น</button>
                    </>
                ) : (
                    <p>No promotion selected</p>
                )}
            </div>
        </div>
    </>

  );
};

export default PromotionModal;
