import React, { useState, useMemo } from 'react';
import { Form, message  } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import DataTable from '../../table/DataTable';
import './UserDetail.css';
import { useTranslation } from 'react-i18next';

function PromotionHistory(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();
    const [accountData, setAccountData] = useState(null);
    const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const columns = useMemo(() => [
        { Header: 'Promotion', accessor: 'bankName' },
        { Header: 'Promo Code', accessor: 'accountNumber' },
        { Header: 'วันที่ขอ', accessor: 'fullName' }
    ], []);

    const fetchAccount = async(username) => {
        try {
          const response = await fetch(apiUrl+'/member/getuser?username='+username, {
            method: 'GET',
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setAccountData(data);
          setIsBankAccountAvailable(!!accountData.bankAccount);
          console.log(data);
        } catch (error) {
          console.error("Get User DATA failed:", error);
        }
    };

    const handleEdit = (id) => {
        navigate(`/user/detail/managebank/edit/${id}`);
    };

    const handleDelete = async(id) => {    

        const method = "DELETE";

        var apiUrl = `${process.env.REACT_APP_API_URL}/member/bankaccount/delete/`+id;
        
        const requestOptions = {
            method,
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            message.success('Bank account deleted successfully!');
            await fetchAccount(auth.username);
        } catch (error) {
            console.error('Error deleting member:', error);
        }
    };

    return (
        <div className="account-content-container">
            <h1 className="account-modal-title">ประวัติการรับโปรโมชั่น</h1>
            <div className="account-content">
                <DataTable columns={columns} data={data} />
                <br></br>
            </div>
        </div>
    );
}

export default PromotionHistory;