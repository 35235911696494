import React, {useState} from 'react';
import './GameSection.css';
import { useAuth } from '../../AuthContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGameDataContext } from '../../GameDataContext';

function GameSection() {

    const apiUrl = process.env.REACT_APP_API_URL;

    const { auth} = useAuth();

    const { state, dispatch, loadMoreGames } = useGameDataContext();
    const { games, hasMore, loadingGame, gamePage, selectedProvider } = state;

    const [searchKeyword, setSearchKeyword] = useState(''); // Search keyword state


    function openNewTab(url) {
        window.open(url, '_blank');
    };

    const onSearchClick = () => {
      console.log("Search keyword:", searchKeyword);
      // handleSearch(searchKeyword);
    };

    const handlePlay = async(gameUniqueId) => {
        
        const raw = JSON.stringify({
          "username": auth.username,
          "gameID": gameUniqueId,
          "language": "th",
          "device" : "desktop",
          "ip": "127.0.0.1"
        });
    
        const requestOptions = {
          method: "POST",
          headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
          body: raw,
          redirect: "follow"
        };
    
        try {
          const response = await fetch(apiUrl+'/member/startgame', requestOptions);
    
          
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
    
          const data = await response.json();
    
          // Check if data.data and data.data.uri exist
          if (data.data && data.data.uri) {
            console.log("Opening new tab with URI:", data.data.uri);
            openNewTab(data.data.uri);
          } else {
            console.error("URI not found in response data:", data);
          }
          
        } catch (error) {
          console.error("Login failed:", error);
        }
    };

    const loadMore = () => {
      if (!loadingGame && hasMore) {
        loadMoreGames(dispatch, apiUrl, state);
      }
    };

    return (
      <div className="game-flex-container">

        <div className="search-container">
          <input
            className="search-input"
            type="text" 
            value={searchKeyword} 
            onChange={(e) => setSearchKeyword(e.target.value)} /* Update the state */
            placeholder="ค้นหาเกมส์" 
          />
          <button className="search-button" onClick={onSearchClick}>ค้นหา</button>
        </div>

        <InfiniteScroll
            className="game-content"
            dataLength={games.length}
            next={loadMore}
            hasMore={hasMore}
        >
            {games.map((game) => (
                <div className="flex-item" key={game.uniqueId}>
                    <img src={game.image.verticalUrl} alt={game.name} />
                    <div className="button-container">
                        <button className="button" onClick={() => handlePlay(game.uniqueId)}>Play</button>
                    </div>
                </div>
            ))}
        </InfiniteScroll>
      </div>
    );
}

export default GameSection;


